import {rigSinglePageAppEntryRoute} from '@/router/lib/rigSinglePageAppEntryRoute.js';

import appRoutes from "../../../chi-app/routes.js";
import apiRoutes from '../routes/apiRoutes.js';
import delphiAppRoutes from "../../../chi-delphi/routes.js";
// import assistantsAppRoutes from "../../../chi-assistants/routes.js";
// import creavisionsAppRoutes from "../../../chi-creavisions/routes.js";
// import ccfAppRoutes from "../../../chi-ccf/routes.js";
// import showcaseRoutes from "../../../chi-showcase/routes.js";

export const getAppRoutes = async (appId) => {
    switch (appId) {
        case 'app': return await rigSinglePageAppEntryRoute(appRoutes, appId)
        case 'api': return await rigSinglePageAppEntryRoute(apiRoutes, appId)
        case 'delphi': return await rigSinglePageAppEntryRoute(delphiAppRoutes, appId)
        // case 'assistants': return await rigSinglePageAppEntryRoute(assistantsAppRoutes, appId)
        // case 'creavisions': return await rigSinglePageAppEntryRoute(creavisionsAppRoutes, appId)
        // case 'ccf': return await rigSinglePageAppEntryRoute(ccfAppRoutes, appId)
        // case 'showcase': return await rigSinglePageAppEntryRoute(showcaseRoutes, appId)
        default: throw new Error(`No app entry route for ${appId}`)
    }
}
